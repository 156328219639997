import { useFormContext } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useState } from 'react'
import VirtualizedCommand from '@/components/VirtualizedCommand'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'

const ComponentSelect = () => {
    const { control } = useFormContext<AddSchema>()

    const { data: productStructureData = [] } = useProductStructureQuery({
        enabled: true,
    })
    const [products, setProducts] = useState<typeof productStructureData>([])

    const form = useFormContext<AddSchema>()
    const companyField = form.watch('companyId')
    const productField = form.watch('productId')

    useEffect(() => {
        if (companyField) {
            const unique = {}

            const filtered = productStructureData.filter(
                (product) => product.SK_EMPRESA.toString() === companyField
            )

            for (const product of filtered) {
                if (!unique[product.SK_PRODUTO_COMPONENTE]) {
                    unique[product.SK_PRODUTO_COMPONENTE] = product
                }
            }

            setProducts(Object.values(unique))
        } else {
            form.resetField('productId')
            setProducts([])
        }
    }, [productStructureData, companyField])

    return (
        <FormField
            name="componentId"
            control={control}
            render={({ field }) => {
                let label = 'Selecione um componente'

                if (!companyField) {
                    label = 'Selecione uma empresa'
                } else if (!productField) {
                    label = 'Selecione um produto'
                } else if (field.value) {
                    const product = productStructureData.find(
                        (comp) => comp.SK_PRODUTO_COMPONENTE == field.value
                    )
                    label = product?.PRODUTO_COMPONENTE || label
                }
                return (
                    <FormItem>
                        <FormControl>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <div className="w-full overflow-hidden">
                                        <FormLabel>
                                            Componente estrutura
                                        </FormLabel>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="flex justify-start w-full overflow-hidden"
                                        >
                                            <TypographyP className="flex-1 overflow-hidden text-start text-ellipsis">
                                                {label}
                                            </TypographyP>
                                        </Button>
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="z-50 w-[400px] p-0"
                                    align="start"
                                >
                                    <VirtualizedCommand
                                        onSelectionChange={(value) => {
                                            field.onChange(value)
                                        }}
                                        options={products.map((component) => {
                                            return {
                                                value: component.SK_PRODUTO_COMPONENTE,
                                                label: component.PRODUTO_COMPONENTE,
                                            }
                                        })}
                                        selectedValues={{ [field.value]: true }}
                                    />
                                </PopoverContent>
                            </Popover>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default ComponentSelect
