import DatePickerMonthWithRange from '@/components/DatePickerMonthWithRange'
import { Separator } from '@/components/ui/separator'
import { informedCostKeys } from '@/queries/useInformedCostQuery'
import { createInformedCost } from '@/api/business/informedCost'
import { useToast } from '@/components/ui/use-toast'
import { getIdFromDate } from '@/utils/date'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
    addDays,
    eachDayOfInterval,
    endOfMonth,
    isBefore,
    isFirstDayOfMonth,
    isSameDay,
    isSunday,
    startOfMonth,
} from 'date-fns'
import { useMemo } from 'react'
import { isAxiosError } from '@/api/business'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { TypographyP } from '@/components/ui/typography'
import { Input } from '@/components/ui/input'
import CompanySelect from './components/CompanySelect'
import ProductSelect from './components/ProductSelect'
import ComponentSelect from './components/ComponentSelect'
import { Company } from '@/types/Company'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import TaxRow from './components/TaxRow'
import TaxGroup from './components/TaxGroup'
import { Button } from '@/components/ui/button'
import CurrencySelect from './components/CurrencySelect'
import { Textarea } from '@/components/ui/textarea'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { structureStatusKeys } from '@/queries/useStructureStatusQuery'
import { costHistoryKeys } from '@/queries/useHistoryQuery'
interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    companyId: z.string().min(1, 'Selecione uma empresa'),
    currencyId: z.coerce.number().min(1, 'Selecione uma moeda'),
    productId: z.array(z.string().min(1, 'Selecione um produto'), {
        message: 'Deve ser selecionado no mínimo um produto',
    }),
    componentId: z.string().min(1, 'Selecione um componente'),
    date: z
        .object({
            from: z.date(),
            to: z.date(),
        })
        .refine(
            ({ from, to }) => !isBefore(to, from) || isSameDay(to, from),
            'Data final não pode ser menor que a data inicial'
        ),
    costValue: z.coerce.number().min(1, 'Valor inválido'),
    freight: z.literal('CIF').or(z.literal('FOB')),
    observation: z.string().optional(),
    pis: z.string().optional(),
    cofins: z.string().optional(),
    icms: z.string().optional(),
    ipi: z.string().optional(),
    st: z.string().optional(),
    fcp: z.string().optional(),
    pis_value: z.string().optional(),
    cofins_value: z.string().optional(),
    icms_value: z.string().optional(),
    ipi_value: z.string().optional(),
    st_value: z.string().optional(),
    fcp_value: z.string().optional(),
})

export type AddSchema = z.infer<typeof schema>

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { data: productStructureData = [] } = useProductStructureQuery({
        enabled: isOpen,
    })

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createInformedCost,
        onSuccess: () => {
            queryClient.invalidateQueries(informedCostKeys.lists())
            queryClient.invalidateQueries(structureStatusKeys.all)
            queryClient.invalidateQueries(costHistoryKeys.all)

            toast({
                title: 'Custo adicionado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar custo',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onSubmit = ({
        companyId,
        componentId,
        costValue,
        date,
        productId,
        currencyId,
        cofins,
        cofins_value,
        fcp,
        fcp_value,
        icms,
        icms_value,
        ipi,
        ipi_value,
        pis,
        pis_value,
        st,
        st_value,
        freight,
        observation,
    }: AddSchema) => {
        const dates = eachDayOfInterval({
            start: startOfMonth(date.from),
            end: endOfMonth(date.to),
        })
            .filter((day) => isFirstDayOfMonth(day))
            .map((day) => {
                return isSunday(day)
                    ? Number(getIdFromDate(addDays(day, 1)).join(''))
                    : Number(getIdFromDate(day).join(''))
            })

        mutate({
            SK_EMPRESA: companyId,
            SK_PRODUTO_COMPONENTE: componentId,
            SK_MOEDA: currencyId,
            SK_PRODUTO_ESTRUTURA: productId,
            SK_TEMPO: dates,
            DD_TIPO_FRETE: freight,
            DD_OBSERVACAO: observation,
            VL_CUSTO_ORCADO: costValue ? costValue.toString() : '0',
            VL_ALIQUOTA_COFINS: cofins || '0',
            VL_COFINS: cofins_value || '0',
            VL_ALIQUOTA_FCP: fcp || '0',
            VL_FCP: fcp_value || '0',
            VL_ALIQUOTA_ICMS: icms || '0',
            VL_ICMS: icms_value || '0',
            VL_ALIQUOTA_IPI: ipi || '0',
            VL_IPI: ipi_value || '0',
            VL_ALIQUOTA_PIS: pis || '0',
            VL_PIS: pis_value || '0',
            VL_ALIQUOTA_ST: st || '0',
            VL_ST: st_value || '0',
        })
    }

    const form = useForm<AddSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            cofins: '0',
            cofins_value: '0',
            companyId: '',
            componentId: '',
            costValue: 0,
            date: { from: new Date(), to: new Date() },
            fcp: '0',
            fcp_value: '0',
            icms: '0',
            icms_value: '0',
            ipi: '0',
            ipi_value: '0',
            pis: '0',
            pis_value: '0',
            productId: [],
            st: '0',
            st_value: '0',
            currencyId: 0,
            freight: 'CIF',
            observation: '',
        },
    })

    const companies = useMemo(() => {
        const data: Record<string, Company> = {}

        for (const row of productStructureData) {
            if (!data[row.SK_EMPRESA]) {
                data[row.SK_EMPRESA] = {
                    SK_EMPRESA: row.SK_EMPRESA.toString(),
                    ABREVIATURA_EMPRESA: row.ABREVIATURA_EMPRESA,
                    CNPJ: '',
                    COD_GRUPO_DE_EMPRESA: '',
                    DESCRICAO_EMPRESA: '',
                }
            }
        }

        return Object.values(data)
    }, [productStructureData])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[900px]">
                <DialogHeader>
                    <DialogTitle>Adicionar custo informado</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex gap-4">
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Estrutura
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="space-y-4">
                                        <CompanySelect companies={companies} />
                                        <ComponentSelect />
                                        <ProductSelect />
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Informações
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="space-y-2">
                                        <FormField
                                            name="date"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Data
                                                        </FormLabel>
                                                        <FormControl>
                                                            <DatePickerMonthWithRange
                                                                date={
                                                                    field.value
                                                                }
                                                                setDate={
                                                                    field.onChange
                                                                }
                                                                className="w-full"
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="freight"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Frete
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Select
                                                                {...field}
                                                                onValueChange={
                                                                    field.onChange
                                                                }
                                                            >
                                                                <SelectTrigger>
                                                                    <SelectValue />
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectItem value="CIF">
                                                                        CIF
                                                                    </SelectItem>
                                                                    <SelectItem value="FOB">
                                                                        FOB
                                                                    </SelectItem>
                                                                </SelectContent>
                                                            </Select>
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="observation"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Observação
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Textarea
                                                                {...field}
                                                                className="resize-none"
                                                                maxLength={200}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div>
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Custo
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="flex gap-4">
                                        <FormField
                                            name="costValue"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem className="flex-1">
                                                        <FormLabel>
                                                            Custo
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                {...field}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <CurrencySelect />
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Impostos
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <TaxRow>
                                        <TaxGroup title="PIS">
                                            <FormField
                                                name="pis"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="pis_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="COFINS">
                                            <FormField
                                                name="cofins"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="cofins_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ICMS">
                                            <FormField
                                                name="icms"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="icms_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="IPI">
                                            <FormField
                                                name="ipi"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="ipi_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ST">
                                            <FormField
                                                name="st"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="st_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="FCP">
                                            <FormField
                                                name="fcp"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="fcp_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                variant="ghost"
                                type="button"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog
