import { useFormContext } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'

import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useState } from 'react'
import VirtualizedCommand from '@/components/VirtualizedCommand'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'

const ProductSelect = () => {
    const { control } = useFormContext<AddSchema>()
    const [selectedComponents, setSelectedComponents] = useState<
        Record<string, boolean>
    >({})

    const { data: componentStructureData = [] } = useProductStructureQuery({
        enabled: true,
    })

    const [components, setComponents] = useState<typeof componentStructureData>(
        []
    )

    const form = useFormContext<AddSchema>()

    const companyField = form.watch('companyId')
    const componentField = form.watch('componentId')
    const productField = form.watch('productId')

    useEffect(() => {
        if (companyField && componentField) {
            const unique = {}

            const filtered = componentStructureData.filter(
                (component) =>
                    component.SK_EMPRESA.toString() === companyField &&
                    component.SK_PRODUTO_COMPONENTE === componentField
            )

            for (const component of filtered) {
                if (!unique[component.SK_PRODUTO_ESTRUTURA]) {
                    unique[component.SK_PRODUTO_ESTRUTURA] = component
                }
            }

            setSelectedComponents({})
            setComponents(Object.values(unique))
        } else {
            form.resetField('componentId')
            setSelectedComponents({})
            setComponents([])
        }
    }, [componentStructureData, componentField, companyField])

    return (
        <FormField
            name="productId"
            control={control}
            render={({ field }) => {
                let label = 'Selecione um componente'
                const product = Object.entries(selectedComponents).filter(
                    ([, value]) => value
                ).length

                if (!companyField) {
                    label = 'Selecione uma empresa'
                } else if (!productField) {
                    label = 'Selecione um produto'
                } else if (product > 0) {
                    label = `${product} selecionados` || label
                }

                return (
                    <FormItem>
                        <FormControl>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <div className="w-full overflow-hidden">
                                        <FormLabel>Produto estrutura</FormLabel>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="flex justify-start w-full overflow-hidden"
                                        >
                                            <TypographyP className="flex-1 overflow-hidden text-start text-ellipsis">
                                                {label}
                                            </TypographyP>
                                        </Button>
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="z-50 w-[400px] p-0"
                                    align="start"
                                >
                                    <VirtualizedCommand
                                        onSelectionChange={(value) => {
                                            setSelectedComponents((prev) => {
                                                const newValue = {
                                                    ...prev,
                                                    [value]: !prev[value],
                                                }

                                                field.onChange(
                                                    Object.entries(newValue)
                                                        .filter(
                                                            ([, val]) => val
                                                        )
                                                        .map(([key]) => key)
                                                )

                                                return newValue
                                            })
                                        }}
                                        options={components.map((component) => {
                                            return {
                                                value: component.SK_PRODUTO_ESTRUTURA,
                                                label: component.PRODUTO_ESTRUTURA,
                                            }
                                        })}
                                        selectedValues={selectedComponents}
                                    />
                                </PopoverContent>
                            </Popover>
                            {/* <MultiSelect
                                isOpen={isMultiSelectOpen}
                                onClose={onMultiSelectClose}
                                onOpen={onOpenMultiSelect}
                                onChange={(value) => {
                                    setSelectedComponents((prev) => ({
                                        ...prev,
                                        ...value,
                                    }))

                                    field.onChange(
                                        Object.entries(value)
                                            .filter(([, val]) => val)
                                            .map(([key]) => key)
                                    )
                                }}
                                options={components.map((component) => {
                                    return {
                                        label: component.PRODUTO_ESTRUTURA,
                                        value: component.SK_PRODUTO_ESTRUTURA,
                                    }
                                })}
                                selectedValues={selectedComponents}
                                label="Selecione um produto"
                                placeholder="Selecione um produto"
                            /> */}
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default ProductSelect
