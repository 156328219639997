import { useFormContext } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import VirtualizedCommand from '@/components/VirtualizedCommand'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'

const ProductSelect = () => {
    const { control, watch, resetField } = useFormContext<AddSchema>()

    const { data: productStructureData = [] } = useProductStructureQuery({
        enabled: true,
    })
    const [products, setProducts] = useState<typeof productStructureData>([])

    const companyField = watch('companyId')
    const productField = watch('productId')

    useEffect(() => {
        if (companyField) {
            const unique = {}

            const filtered = productStructureData.filter(
                (product) => product.SK_EMPRESA.toString() === companyField
            )

            for (const product of filtered) {
                if (!unique[product.SK_PRODUTO_ESTRUTURA]) {
                    unique[product.SK_PRODUTO_ESTRUTURA] = product
                }
            }

            setProducts(Object.values(unique))
        } else {
            resetField('productId')
            setProducts([])
        }
    }, [productStructureData, companyField])

    return (
        <FormField
            name="productId"
            control={control}
            render={({ field }) => {
                let label = 'Selecione um produto'

                if (!companyField) {
                    label = 'Selecione uma empresa'
                } else if (!productField) {
                    label = 'Selecione um produto'
                } else if (field.value) {
                    const product = productStructureData.find(
                        (comp) => comp.SK_PRODUTO_ESTRUTURA == field.value
                    )
                    label = product?.PRODUTO_ESTRUTURA || label
                }

                return (
                    <FormItem>
                        <FormControl>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <div>
                                        <FormLabel>
                                            Componente estrutura
                                        </FormLabel>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="flex justify-start w-full overflow-hidden"
                                        >
                                            <TypographyP className="flex-1 overflow-hidden text-start text-ellipsis">
                                                {label}
                                            </TypographyP>
                                        </Button>
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="z-50 w-[400px] p-0"
                                    align="start"
                                >
                                    <VirtualizedCommand
                                        onSelectionChange={(value) =>
                                            field.onChange(value)
                                        }
                                        options={products.map((component) => {
                                            return {
                                                value: component.SK_PRODUTO_ESTRUTURA,
                                                label: component.PRODUTO_ESTRUTURA,
                                            }
                                        })}
                                        selectedValues={{ [field.value]: true }}
                                    />
                                </PopoverContent>
                            </Popover>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
                // return (
                //     <FormItem>
                //         <FormLabel>Produto estrutura</FormLabel>
                //         <FormControl>
                //             <Select
                //                 onValueChange={field.onChange}
                //                 value={field.value}
                //             >
                //                 <SelectTrigger>
                //                     <SelectValue
                //                         placeholder={
                //                             !companyField
                //                                 ? 'Selecione uma empresa'
                //                                 : 'Selecione um produto'
                //                         }
                //                     />
                //                 </SelectTrigger>
                //                 <SelectContent>
                //                     <SelectGroup>
                //                         {products.map((product) => {
                //                             return (
                //                                 <SelectItem
                //                                     key={
                //                                         product.SK_PRODUTO_ESTRUTURA
                //                                     }
                //                                     value={
                //                                         product.SK_PRODUTO_ESTRUTURA
                //                                     }
                //                                 >
                //                                     {product.PRODUTO_ESTRUTURA}
                //                                 </SelectItem>
                //                             )
                //                         })}
                //                     </SelectGroup>
                //                 </SelectContent>
                //             </Select>
                //         </FormControl>
                //         <FormMessage />
                //     </FormItem>
                // )
            }}
        />
    )
}

export default ProductSelect
